import React from 'react';
import {BrowserRouter,Route, Link} from "react-router-dom";
import PageMain from "./PageMain";
import PageBlog from "./PageBlog"
import './App.css';
import PagePatternFactory from "./PagePatternFactory";
import {Container} from "@material-ui/core"

function App() {
  return <div className="App">
        <BrowserRouter>
            <Container maxWidth={"md"} style={{padding:"12px 12px 12px 12px"}}>
                <div className={"raised-div"} style={{marginBottom:"0.5rem"}}>
                    <Link to={"/"}>🏠</Link> {/*Home emoji*/}
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    <Link to={"/patternfactory"}>Pattern Factory</Link>
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    <Link to={"/blog"}>Blog</Link>
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    <a href={"https://swiftmissive.com"} target={"_blank"} rel="noreferrer">Swift Missive</a>
                </div>
                <div className={"raised-div"} >
                    <Route exact path="/" component={PageMain} />
                    <Route exact path="/blog" component={PageBlog} />
                    <Route exact path="/patternfactory" component={PagePatternFactory} />
                    <Route exact path="/how-to-build-an-electronic-drumset-for-300-bucks"  component={PageBlog} />
                    <Route exact path="/how-to-create-artificial-intelligence-with-genetic-alg"  component={PageBlog} />
                    <Route exact path="/where-should-i-live"  component={PageBlog} />
                    <Route exact path="/guidelines-for-using-lambdas-in-production"  component={PageBlog} />
                </div>
            </Container>
        </BrowserRouter>
    </div>;
}

export default App;
