import React from 'react';
import {Link} from "react-router-dom"
import {Typography} from "@material-ui/core";

export default function PageMain(props: any) : JSX.Element {
    return <>
        <Typography variant={"h2"} style={{textAlign:"center"}}>
            Welcome to the official<br/>
            Final Dave LLC homepage!
        </Typography>
        <br />
        <br />
        <Typography>
            ✉️ For information on Swift Missive, our cost-effective email service provider, click
            <a href={"https://swiftmissive.com/pricing"} target={"_blank"} rel={"noreferrer"}>here</a>
        </Typography>
        <br />
        <Typography>
            🤖 For information on Pattern Factory, the puzzle game for Android devices, click
            <Link to={"/patternfactory"}>here</Link>
        </Typography>
        <br />
        <Typography>
            📓 For a blog containing random thoughts and projects, click
           <Link to={"/blog"}>here</Link>
        </Typography>
        <br />
    </>
}