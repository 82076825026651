import React from 'react';
import {Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import HowToBuildADrumsetFor300Bucks from "./blog/how-to-build-an-electronic-drumset-for-300-bucks";
import GeneticAlgorithm from "./blog/how-to-create-artificial-intelligence-with-genetic-alg";
import WhereShouldILive from "./blog/where-should-i-live";
import LambdaLessons from "./blog/guidelines-for-using-lambdas-in-production";

export default function PageBlog(props: any) : JSX.Element {
    return <div>
        {props.location.pathname === "/blog" && <>
            <Typography variant={"h3"}>Blog Posts</Typography>
            <div style={{marginTop:"10px"}}>2021 <Link to={"/guidelines-for-using-lambdas-in-production"}>Guidelines for using lambdas in production</Link></div>
            <div style={{marginTop:"10px"}}>2019 <Link to={"/how-to-build-an-electronic-drumset-for-300-bucks"}>How to build an electronic drumset for $300</Link></div>
            <div style={{marginTop:"10px"}}>2017 <Link to={"/where-should-i-live"}>Project: Where should I live?</Link></div>
            <div style={{marginTop:"10px"}}>2014 <Link to={"/how-to-create-artificial-intelligence-with-genetic-alg"}>How to create artificial intelligence using a basic genetic algorithm (with demo!)</Link></div>
        </>}
        {props.location.pathname === "/guidelines-for-using-lambdas-in-production" && <LambdaLessons />}
        {props.location.pathname === "/how-to-build-an-electronic-drumset-for-300-bucks" && <HowToBuildADrumsetFor300Bucks />}
        {props.location.pathname === "/how-to-create-artificial-intelligence-with-genetic-alg" && <GeneticAlgorithm />}
        {props.location.pathname === "/where-should-i-live" && <WhereShouldILive />}
    </div>
}