import React from 'react';
import { Typography } from '@material-ui/core';

export default function HowToCreateAIWithGeneticAlgorithm(props?: any) {
    return <div>
        <Typography variant={"h3"}>How to create Artificial Intelligence <br /> using a basic Genetic Algorithm</Typography>
        <Typography style={{marginTop:"30px"}}>I was feeling a little under the weather yesterday, so I decided to hang out in cafes and code some artificial intelligence using a basic genetic algorithm. It was really fun coding and I learned quite a bit, which I'm happy to share with you here.  If you want to understand the basics of genetic algorithms, or to see a demo of one in action, read on!</Typography>
        <Typography style={{marginTop:"10px"}}>The basic steps to using a genetic algorithm are:</Typography>
        <ol>
            <li><Typography>Define the problem you need to solve :)</Typography></li>
            <li><Typography>Create and record a set of attempts at solving that problem. For instance, if you are trying to make a cake, you might select random ingredients from your cupboards and combine them. Do that 10 different times and keep a list of the ingredients you used, and that list is your set of attempts at making a cake.</Typography></li>
            <li><Typography>Assign a score to each attempt at solving your problem. You might do this by baking then tasting each combination of ingredients for example.</Typography></li>
            <li><Typography>Selectively "breed" your attempts from step 2 to produce an equal number of "child attempts", like so:</Typography>
                <ol>
                    <li><Typography>Pick two attempts "at random". The attempts with the higher scores should be proportionally easier to pick, but wont necessarily be. Think of a roulette wheel will certain spaces being wider than others--the ball is more likely to land in the larger spaces but it is still a random selection.</Typography></li>
                    <li><Typography>Combine the "genes" or ingredients that make up the two attempts you selected, to produce a new child attempt. For instance, "eggs eggs eggs butter" might be crossed with "flour water flour chilli-powder" to produce "eggs eggs eggs chilli-powder" (blah!) or "flour water flour eggs" (getting closer!).</Typography></li>
                    <li><Typography>Pick a random number between 1 and 100. If it's greater than 99 or so, then randomly change some of the genes in the new child we just created. "eggs eggs eggs chilli-powder" might become "eggs flour eggs chilli-powder" for instance. Usually these "mutations" won't happen, but we do need them from time to time.</Typography></li>
                </ol>
            </li>
            <li><Typography>Using this new generation of attempts, repeat step 3.  If any of your scores are greater than an acceptable threshold (i.e. "That tastes like an amazing cake!"), then you have succeeded in solving the problem using a genetic algorithm! If none of your scores reach the threshold, then repeat step 4 to create new child attempts, using the new generation of attempts from step 4 as your new parent generation. Between the higher likelihood of good traits being passed on, and the random mutations, you will eventually have yourself a darn-fine cake.</Typography></li>
        </ol>
        <Typography variant={"h4"}>Demo Time!</Typography>
        <iframe src={"./aitanks/index.html"}
                style={{marginTop:"20px",width:"100%",height:"650px", borderStyle:"none", backgroundColor:"#eee"}}></iframe>
        <Typography style={{marginTop:"20px"}}>I picked baking a cake because it is an easy example to relate to. However, "How good is this cake recipe?" is not a question computers can really answer (yet?!).  So, in order to play with the algorithm above, I decided to ask the computer "Can you make two tanks shoot each other?"</Typography>
        <Typography style={{marginTop:"10px"}}>Each tank in the demo takes 20 shots in random directions when the application loads. Pressing the "fire" button will tell the tanks to evaluate their previous shots, and to use the genetic algorithm to produce a new set of shots that are closer to the other tank than the previous ones. They usually hit each other 19 or 20 times out of 20 after the first few attempts (of 20 each). </Typography>
        <Typography style={{marginTop:"10px"}}>Why don't they hit each other 100% of the time after they find the right firing angle? Well, that's because I left in the random mutations that cause the shots to go off in random directions from time to time. I could turn off these mutations as soon as one tank hit the other, since we can't really have a "better than perfect" solution, but, that would reduce the adaptability of the system. What if I added a "move tanks" button, for instance? The tanks wouldn't be able to hit each other any more, because all they can do without mutation is average out their better shots.  So basically, I left in the random mutations, just on the off chance that I end up adding a move tank button or some other randomness to the system.</Typography>

    </div>
}

