import React from 'react';
import {Grid, Typography} from '@material-ui/core'

export default function PatternFactory(props: any) : JSX.Element {
    return <>
        <Grid container style={{marginBottom:"20px"}}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{marginRight:"20px"}}>
                <Typography variant={"h3"} style={{marginBottom:"1rem"}}>Introduction</Typography>
                <Typography style={{marginBottom:"1rem"}}>
                    Pattern factory is a unique and challenging puzzle game for android mobile and tablet devices.
                </Typography>
                <Typography style={{marginBottom:"1rem"}}>
                    You start out the game as a newbie at a Pattern Factory, where you train to put quirky
                    machines together on an assembly line, with the ultimate goal of having them work together to drill
                    specific patterns into pieces of sheet metal.  As you get more familiar with the game and complete
                    more and more levels, the patterns you are asked to drill and the number of machines you'll
                    need to use become more and more complex.
                </Typography>
            </Grid>
            <Grid item>
                <iframe width="420" height="315" src="https://www.youtube.com/embed/aSRqCXhJ5hw"
                        data-src="https://www.youtube.com/embed/aSRqCXhJ5hw"
                        frameBorder="0" allowFullScreen
                        title={"Pattern Factory 1"} style={{marginTop:"10px"}} />
                <Typography>Above:  The Pattern Factory promo video</Typography>
            </Grid>
        </Grid>
        <Grid container style={{marginBottom:"20px"}}>
            <Grid item >
                <iframe width="420" height="315" src="https://www.youtube.com/embed/Nft_Yyhvh4U"
                        data-src="https://www.youtube.com/embed/Nft_Yyhvh4U"
                        frameBorder="0" allowFullScreen
                        title={"Pattern Factory Gameplay"} style={{marginRight:"20px", marginTop:"10px"}}/>
                <Typography>Above:  A gameplay walkthrough of a typical easy-mode puzzle</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Typography variant={"h3"} style={{marginBottom:"1rem"}}>Gameplay</Typography>
                <Typography style={{marginBottom:"1rem"}}>
                    Pattern factory is a unique and challenging puzzle game for android mobile and tablet devices.
                </Typography>
                <Typography style={{marginBottom:"1rem"}}>
                    It has been described as:
                </Typography>
                <ul>
                    <li>A challenging but addictive puzzle game for android phones and tablets.</li>
                    <li>One of the few great Android games designed specifically with Android tablets in mind.</li>
                    <li>A fun addition to puzzle games like sudoku and crosswords.</li>
                    <li>An excellent game for people with strong spacial reasoning skills.</li>
                </ul>
            </Grid>
        </Grid>
        <Grid container style={{marginBottom:"1rem"}}>
            <Grid item>
                <Typography variant={"h3"}>Meet the Machines</Typography>
            </Grid>
        </Grid>
        <Grid container style={{marginBottom:"10px"}}>
            <Grid item>
            <Typography variant={"h5"}>Drill Machine</Typography>
            </Grid>
        </Grid>
        <Grid container style={{marginBottom:"10px"}}>
            <Grid item xs={4} sm={4} md={3} lg={3} xl={3} style={{minWidth:"100px"}} >
                <img src={"/menu_machine_drill.png"} alt={"drill machine"} style={{float:"right",marginRight:"50px"}}/>
            </Grid>
            <Grid item xs={8} sm={8} md={8} lg={8} xl={9}>
                <br />
                <Typography>Purpose: To drill holes in the sheet metal.</Typography>
                <br />
                <Typography>Favorite Pun: I went to a very emotional wedding the other day. Even the cake was in tiers.</Typography>
            </Grid>
        </Grid>
        <Grid container style={{marginBottom:"10px"}}>
            <Grid item>
            <Typography variant={"h5"}>Rotate 90 Degrees Machine</Typography>
            </Grid>
        </Grid>
        <Grid container style={{marginBottom:"10px"}}>
            <Grid item xs={4} sm={4} md={3} lg={3} xl={3} style={{minWidth:"100px"}} >
                <img src={"/menu_machine_rotate_90_degrees_left.png"} alt={"rotate machine"} style={{float:"right",marginRight:"50px"}}  />
            </Grid>
            <Grid item xs={8} sm={8} md={8} lg={8} xl={9}>
                <br />
                <Typography>Purpose: To rotate sheet metal counter-clockwise.</Typography>
                <br />
                <Typography>Favorite Pun: Did you hear about the guy who got hit in the head with a can of soda? He was lucky it was a soft drink.</Typography>
            </Grid>
        </Grid>
        <Grid container style={{marginBottom:"10px"}}>
            <Grid item>
                <Typography variant={"h5"}>Horizontal Flip Machine</Typography>
            </Grid>
        </Grid>
        <Grid container style={{marginBottom:"10px"}}>
            <Grid item xs={4} sm={4} md={3} lg={3} xl={3} style={{minWidth:"100px"}} >
                <img src={"/menu_machine_flip_horizontal.png"}  alt={"drill machine"} style={{float:"right",marginRight:"30px"}}  />
            </Grid>
            <Grid item xs={8} sm={8} md={8} lg={8} xl={9}>
                <br />
                <Typography>Purpose: To flip the sheet metal horizontally.</Typography>
                <br />
                <Typography>Favorite Pun: I used to have a fear of hurdles, but I got over it.</Typography>
            </Grid>
        </Grid>
        <Grid container style={{marginBottom:"10px"}}>
            <Grid item>
                <Typography variant={"h5"}>Reset Machine</Typography>
            </Grid>
        </Grid>
        <Grid container style={{marginBottom:"10px"}}>
            <Grid item xs={4} sm={4} md={3} lg={3} xl={3} style={{minWidth:"100px"}} >
                <img src={"/menu_machine_reset_cropped2.png"}  alt={"reset machine"} style={{float:"right",marginRight:"40px",marginTop:"5px"}}  />
            </Grid>
            <Grid item xs={8} sm={8} md={8} lg={8} xl={9}>
                <Typography>Purpose: To return the sheet metal to the beginning of the assembly line.</Typography>
                <br />
                <Typography>Favorite Pun: I saw a beaver movie last night, it was the best dam movie I've ever seen.</Typography>
            </Grid>
        </Grid>
        <Grid container style={{marginBottom:"10px"}}>
            <Grid item>
                <Typography variant={"h5"}>Placeholder Machine</Typography>
            </Grid>
        </Grid>
        <Grid container style={{marginBottom:"10px"}}>
            <Grid item xs={4} sm={4} md={3} lg={3} xl={3} style={{minWidth:"100px"}} >
                <img src={"/menu_machine_not_set.png"}  alt={"placeholder machine"} style={{float:"right",marginRight:"40px", width:"150px"}}  />
            </Grid>
            <Grid item xs={8} sm={8} md={8} lg={8} xl={9}>
                <Typography>Purpose: To stand around and make funny jokes while the other machines do all the work.</Typography>
                <br />
                <Typography>Favorite Pun: I was going to buy a book on phobias, but I was afraid it wouldn't help me.</Typography>
            </Grid>
        </Grid>
        <Grid container style={{marginTop:"10px"}}>
            <Grid item>
                <br />
                <br />
                <br />
                <Typography>
                <b>Links</b><br />
                Thanks to <a href={"https://www.kasradesign.com/"} target={"_blank"} rel="noreferrer">Kasra Design</a> for the Johnny image used in the game.
                </Typography>
            </Grid>
        </Grid>
    </>
}